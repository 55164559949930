import styles from './Text.module.css';
export const articles=[{
    id:1,
    image:"https://pngimg.com/uploads/hello/hello_PNG28.png",
    author:"Ben Carmeli",
    createddate:"10-24-2022",
    headline:"A Fresh Start",
    text:"This is the first post!",
    html:<div>
        <h2>This is the first post!</h2>
    </div>,
    
    },
    {id:10,
        image:"https://sportylemur.com/images/eagleslost.png",
        author:"Ben Carmeli",
        createddate:"11-17-2022",
        headline:"Undefeated Defeated",
        text:"The Philadelphia Eagles hopes of a perfect season were shattered by the Washington Commanders",
    html:<div>
        <h3>Shattered...</h3>
        <p>The Philadelphia Eagles hopes of a perfect season were shattered by the Washington Commanders in a 32-21 game. The match itself was full of nail-biting moments and surprise plays. Though both teams kept each other in check with various touchdowns and field goals, the pressure was tightened by a first quarter Commanders fumble by Heinicke and a second quarter Eagles interception by Darrick Forrest. The 4th quarter rolls on by, the score was 23-21, and Commanders are intercepted by CJ Gardner-Johnson but ultimately it leads no where as the Eagles fumble three times and Commanders secure the win with another field goal.</p>
        <br/>
        <h3>So what now?</h3>
        <p>Though it seemed the Eagles might recreate the 1972 Miami Dolphins’ perfect season, it seems it just wasn’t meant to be. This turn of events seemed even more unlikely given that the Commanders were 4-5 compared to the Eagles’ 8-0. Despite the odds the Commanders were able to break the Eagles streak, forcing them to postpone their dreams of a perfect season until next year.</p>
        <br/>
        </div>,
        
    },
    {
        id:11,
        image:"https://sportylemur.com/images/ramswhathappened.png",
        author:"Ben Carmeli",
        createddate:"11-18-2022",
        headline:"Rams: What Happened?",
        text:"After winning the 2021 Super Bowl in a 23-20 game against the Bengals, the Rams seemed unstoppable. With an all-star roster including the likes of Aaron Donald, Cooper Kupp, and Matthew Stafford, you would think they would give other teams a run for their money in the 2022 season.",
        html:<div>
            <h2>A Strong Start</h2>
        <p>After winning the 2021 Super Bowl in a 23-20 game against the Bengals, the Rams seemed unstoppable. With an all-star roster including the likes of Aaron Donald, Cooper Kupp, and Matthew Stafford, you would think they would give other teams a run for their money in the 2022 season.</p>
        <br/>
        <h2>What's the difference?</h2>
        <p>But then the 2022 season began, and the team seems unrecognizable. As of writing this the Rams are at 3 wins and 6 losses, quite the unprecedented turn of events. So how do the rams go from winning the Super Bowl to losing most of their games just a few months later? It seems much of the trouble stems from a lackluster offensive-line. Stafford in 2021 attempted 601 passes and was sacked 30 times; remember that is over the entire year. So far this year he has 285 pass attempts and has been sacked 28 times, and the year isn’t even close to being over. The offensive lineman Joe Noteboom, Rob Havenstein, and Bobby Evans have allowed 23, 20, and 19 pressures respectively. </p>
        <br/>
        <p>A missing piece of the o-line puzzle is left tackle Andrew Whitworth, who retired after winning the Super Bowl. If the rams hope to improve over the next few weeks they MUST fix this offensive line. On top of all this there are various injured players such as Stafford (which will resume playing in week 11) and Kupp who is getting ankle surgery. The story of the Rams as it stands seems to be a cautionary tale of how the great can fall so hard, so fast, and so quickly.</p>
    	</div>,
        
    },
    {
        id:15,
        image:"https://sportylemur.com/images/theydidit.png",
        author:"Ben Carmeli",
        createddate:"12-5-2022",
        headline:"THEY DID IT",
        text:"The US had to fight hard to get their goal as Iran had a strong defensive presence. The breakthrough came at 37 minutes when Pulisic risked it all in an epic finishing move. The goal was so intense he ended up injured and went to the hospital after the match.",
        html:<div>
           <h2>THEY DID IT!</h2> 
        <p>
        The United States won 1-0 against Iran in the first stage of the World Cup. After back to back ties against Wales and England, this win secures the US team to move onto the knockout stage. 
         </p>
   <h2>
    It wasn’t easy
    </h2>
    <p>The US had to fight hard to get their goal as Iran had a strong defensive presence. The breakthrough came at 37 minutes when Pulisic risked it all in an epic finishing move. The goal was so intense he ended up injured and went to the hospital after the match.  You can view that goal and other match highlights in the video below. </p>
    <div className={styles.iframeContainer}>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/TNl7ZcnDB0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h2>Can they keep it up?</h2>
    <p>
    Their next match will be on Saturday December 3rd against Netherlands. Hopefully the team keeps it up and keeps improving.
    </p>
    </div>,
    },
    {
        id:16,
        image:"https://cms.qz.com/wp-content/uploads/2018/07/AP_18184636496894-e1530903983148.jpg?quality=75&strip=all&w=1200&h=630&crop=1",
        author:"Ariel Carmeli",
        createddate:"12-7-2022",
        headline:"The World Cup So Far",
        text:"During this two day reprieve from the heart pounding high stakes games of the world cup we can contemplate on what's to come. We have some exciting quarter finals matches coming up with many teams that have a lot to prove. On December 9th Croatia, the previous world cup's second place finalists, will face off against Brazil, the 5 time champions who are coming off from a very commanding and dancy 4-1 win over South Korea. This game is sure to cause some nail biting moments. Later on in the day the Netherlands, fresh off their win booting the USA team back home will face off against the only other South American team left besides Brazil, Argentina. Argentina has rebounded solidly after their shaky loss to Saudi Arabia in the opening game but we'll see if that can hold up in this next match.",
        html:<div>
            <p>
            During this two day reprieve from the heart pounding high stakes games of the world cup we can contemplate on what's to come. We have some exciting quarter finals matches coming up with many teams that have a lot to prove. On December 9th Croatia, the previous world cup's second place finalists, will face off against Brazil, the 5 time champions who are coming off from a very commanding and dancy 4-1 win over South Korea. This game is sure to cause some nail biting moments. Later on in the day the Netherlands, fresh off their win booting the USA team back home will face off against the only other South American team left besides Brazil, Argentina. Argentina has rebounded solidly after their shaky loss to Saudi Arabia in the opening game but we'll see if that can hold up in this next match.            </p>
            <p>
            As if those games weren't going to keep us on the edge of our seats, the next day December 10th we're about to get even more intense. We've got Morocco, the dark horse of the group which pulled an unexpected penalty win against Spain, up against Portugal fresh off a 6-1 domination against Switzerland which had Cristiano Ronaldo benched for most of the match. I'm eager to see if Morocco can keep up with the other heavy hitters left because I love a good underdog story. Finally, arguably the most hyped match with two soccer giants, England against France. My money's on solid win by France with Mbappé leading the way but I wouldn't be surprised if England was able to pull off a win as they proven to be no slouch either this world cup. Regardless of who you're rooting for the lineup of matches is enough to get anyone's adrenaline pumping just thinking about it. Set your alarm clocks fans because you won't want to miss these quarter finals.            </p>
        </div>,
    },
    {
        id:17,
        image:"https://www.ewrestlingnews.com/wp-content/uploads/2022/12/Argentina-.jpg",
        author:"Ben Carmeli",
        createddate:"12-20-2022",
        headline:"Argentina Wins!",   
        text: "The World Cup 2022 final was as nail biting as it gets full of tense moments and upsets. Messi ultimately led the Argentina pack to victory, but it wasn’t easy.",
        html:<div>
            <p>
            The World Cup 2022 final was as nail biting as it gets full of tense moments and upsets. Messi ultimately led the Argentina pack to victory, but it wasn’t easy. 
            </p>
            <p>
            In the first half we saw a super dominant Argentina lead by 2-0. The second half might as well have been called the revenge of France because Mbappé managed to make 2 goals within the span of a few minutes.
            </p>
            <p>
            In overtime Messi makes an epic goal and the match seems to be over but then a handball penalty ties it up AGAIN. The match went into penalties and Argentina did some serious work to secure the win.
            </p>
            <p>
            Some are calling this the best World Cup final within the last 50 years, and I don’t think they’re wrong!
            </p>
        </div>,
    }
];

