import {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import Article from '../components/Article';
import ArticleMain from '../components/ArticleMain';
export default function ArticlePage(props){

const params=useParams();
const [entry,changeEntry]=useState(props.articles)


useEffect(()=>{
    changeEntry(props.articles.find(x=>{return x.id==params.id}));
},[props.articles])

//console.log(entry);

return <div>
   {/*  If not empty then display the article */}
    {entry && <ArticleMain key={entry.id} data={entry}/>}

   
</div>
}