import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import styles from "./ArticleMain.module.css";
import { useState, useEffect, useRef } from "react";

import { Typography } from "@mui/material";


import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { TwitterIcon, LinkedinIcon } from "react-share";
import {articles} from "../articles/articles";
import textStyle from "../articles/Text.module.css";


export default function ArticleMain(props) {
  const [data, setData] = useState(props.data);
  const currentData = useRef(data);
  const articleMount = useRef(false);
 // let articleText=JSON.stringify(data.text);
  
  const identified=useRef(false);
  const [text,setText]=useState([]);
 // const edited=useRef(false);

  if(identified.current===false){
    for(let i =0;i<articles.length;i++){
      console.log("checking id:"+i);
      console.log("the article id:"+articles[i].id);
      if(props.data.id==articles[i].id){
        identified.current=true;
        console.log("identified!");
        setText(articles[i].html);
      }
    }
    }
  //share url
  const url = window.location.href;

  const subHeadStyle = {
    marginRight: 5,
  };

  const creditStyle = {
    color: "gray",
    fontSize: {
      xs: "0.5rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1rem",
    },
    fontStyle: "italic",
  };

  const authorStyle = {
    float: "left",
    color: "gray",
    fontSize: {
      xs: "0.5rem",
      sm: "1rem",
      md: "1rem",
      lg: "1rem",
      xl: "1rem",
    },
    fontStyle: "italic",
    
  };

  const headLineStyle = {
    color: "navy",
    fontSize: "2em",
    textAlign: "left",
    fontFamily: "Bungee",
     marginTop:"1em",
    marginBottom:"1em"
  };

  return (

    <Grid container direction="column" alignItems="center">

      <Card
        sx={{ minWidth: 180, width: 0.8, height: 0.8, alignContent: "center" }}
      >

         <CardContent>
          <div className={styles.maindiv}>
            <img className={styles.image} src={data.image}></img>
            <Typography sx={creditStyle}>Image credit {data.image}</Typography>

            <div>
              <Typography sx={headLineStyle}>{data.headline}</Typography>

              <Typography sx={authorStyle}>
                <span style={subHeadStyle}>Author:{data.author}</span>
                <span style={subHeadStyle}>Published:{data.createddate}</span>
                {data.editeddate && (
                  <span style={subHeadStyle}>Edited: {data.editeddate}</span>
                )}
              </Typography>

              <br />
              <hr/>
             
             {/*  //////////////////////////////////////////////////////////////// */}
             <div className={textStyle.textdiv}>
             {text}
             </div>
             
              {/*  //////////////////////////////////////////////////////////////// */}
                
            </div>
          </div>
          <hr/>
          <div className={styles.icon}>
           
            <Typography
              sx={{ float: "left", justifyContent: "center", padding:2 }}
            >
              <Typography>Share on</Typography>
              <TwitterShareButton url={url} title={data.headline}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={url} title={data.headline}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
