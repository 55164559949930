
import Article from "../components/Article";
import mainStyles from "../App.module.css";
import Carousel from "../components/Carousel";
import { Stack } from "@mui/system";
import { Grid } from "@mui/material";
import Sidebar from "../components/Sidebar";
import {Typography} from "@mui/material";
import { useState,useEffect } from "react";



const Home =(props)=>{

  const [trendingArticles,setTrendingArticles]=useState();

 const carouselArticles= props.articles.slice(0,4);


const sideBarStyles={
  backgroundColor:"navy",
  borderRadius:5,
  padding:1,
  display:{
    xs:"none",
    sm:"none",
    md:"inline",
    lg:"inline",
    xl:"inline"
  },
  textAlign:"center",
  fontSize:"1em"
}


    return <div>
        
        
        <div className={mainStyles.stackStyle}>
        {/* <div style={containerStyles}>
        {props.articles.length>0 && <Carousel data={carouselArticles}/>}
        </div> */}
        
        
        {props.articles.length>0 && <Carousel data={carouselArticles}/>}
        
        
        <Grid container spacing={0} >
          <Grid container item xs={12} s={12} md={12} lg={12} xl={12} direction="column" sx={{display:"flex", alignItems:"center", }}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              {props.articles.map((x) => (
                <Article key={x.id} data={x} />
              )).reverse()}
            </Stack>
          </Grid>
          
          {/* The trending sidebar */}
         
        </Grid>
      </div>
    </div>
}

export default Home;