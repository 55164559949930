import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useState,useContext } from 'react';
import AuthContext from '../../Context/context';
import styles from './Navbar.module.css';
import Modal from "../Modal";
import Searchbutton from './Searchbutton';
import Loginbutton from './Loginbutton';

export default function Navbar() {

  const ctx=useContext(AuthContext);

  const [modal,setModal]=useState(false);

  const searchStyle={
    fontFamily:"Bungee",
    display:{
    xs:"none",
    sm:"none",
    md:"flex",
    lg:"flex",
    xl:"flex"
  },fontSize:"1em"
}

  const buttonStyle={
    fontFamily:"Bungee",
    display:{
      xs:"none",
      sm:"contents",
      md:"contents",
      lg:"contents",
      xl:"contents"
  },fontSize:"1em",
  display:"flex",
  alignContent:"center"
  
}

const menuStyle={
  display:{
    xs:"contents",
    sm:"none",
    md:"none",
    lg:"none",
    xl:"none"
    },
   
  }

const loginStyle={
  display:{
    xs:"none",
    sm:"contents",
    md:"contents",
    lg:"contents",
    xl:"contents"
  }
}

  const buttonSize={
    maxWidth: '2em', maxHeight: '20px', minWidth: '2em', minHeight: '20px',
    marginRight:{
      xs:"1em",
      sm:"1em",
      md:"4em",
      lg:"4em",
      xl:"4em"
    }
  
  }

 
const modalHandler=()=>{
  setModal(true);
}

  const logoutHandler=()=>{
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userName');
    localStorage.removeItem('verified');
    window.location.reload();
  }

 
  return (
  <>
 {modal && <Modal isLoggedIn={ctx.isLoggedIn} logoutHandler={logoutHandler} setModal={setModal}/>} 
  
    <Box sx={{ flexGrow: 1,   }}>
      <AppBar position="static">
        <Toolbar sx={{backgroundColor:"rgb(255,41,41)", background:"linear-gradient(90deg, rgba(255,41,41,1) 0%, rgba(39,77,241,1) 65%)"}}>

        <Link to="/" style={{textDecoration:'none', color:'inherit',}}>
          <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          >
          <Typography sx={{fontFamily:"Bungee Inline"}}>S</Typography>
          <Typography sx={{fontFamily:"Archivo Black",}}>L</Typography>
          </IconButton>
          </Link>
          
          <Link to="/" style={{textDecoration:'none', color:'inherit',}}>
          <Typography  component="span" sx={{ flexGrow: 1,position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)" }}>
              <div className={styles.titleStyle}>
              <span style={{fontFamily:"Bungee Inline"}}>Sporty</span> <span style={{fontFamily:"Archivo Black",}}>LEMUR</span>  
              </div>
          </Typography>
          </Link>          
            
          <Searchbutton/>

        </Toolbar>
        
      </AppBar>
    </Box>
    </>
  );
}