import {Card,CardContent,Typography} from "@mui/material";
export default function AboutPage() {
  return (
    <Card
      sx={{
        minWidth: 180,
        width: 0.8,
        height: 0.8,
        backgroundColor: "#F5F5F5",
        "&:hover": { backgroundColor: "white" },
        margin:"0px auto",
        textAlign:"center"
      }}
    >
      <CardContent>
        <Typography sx={{fontFamily:"Bungee", fontSize:"3em",textDecoration:"underline"}}>
            About SportyLemur
        </Typography>
        <Typography sx={{fontFamily:"Kanit", fontSize:"2rem"}}>
            SportyLemur is your one stop for all the latest and greatest sports news. Created by Ben Carmeli.
        </Typography>
        <Typography sx={{fontFamily:"Kanit", fontSize:"1rem",color:"gray",mt:5}}>
           Background Image Credit:<a href="https://www.freepik.com/free-vector/american-flag-with-firework-display-background_15356190.htm#query=patriotic%20background&position=19&from_view=search&track=sph#position=19&query=patriotic%20background">Image by starline</a> on Freepik
        </Typography>
      </CardContent>
    </Card>
  );
}
