import styles from "./Modal.module.css";
import { Button,Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from '../Context/context';
export default function Modal(props) {
 
 const ctx = useContext(AuthContext);

 const closeHandler=()=>{
    props.setModal(false);
 }

 const logoutHandler=()=>{
    props.logoutHandler();
    props.setModal(false);
 }
 

    return (
    <div className={styles.modalStyle}>
      
      <div className={styles.modalContent}>
      <h1>Menu</h1>
        <div className={styles.modalSubContent}>
        
        {!ctx.user.isLoggedIn && (
          <div style={{marginBottom:5}}>
          <Link
            to="/login"
            style={{ textDecoration: "none", color: "inherit", flexGrow: 0 }}
          >
            <Button variant="contained" color="inherit" sx={{backgroundColor:"#007FFF",width:"100%",color:"white"}}  onClick={closeHandler}>Login</Button>
          </Link>
          </div>
        )}
        {ctx.user.isLoggedIn && (
           <div>
          <Link
            to="/"
            style={{ textDecoration: "none", color: "inherit", flexGrow: 0 }}
          >
            <Button  variant="contained" color="inherit" sx={{backgroundColor:"#007FFF",width:"100%"}} onClick={logoutHandler}>
              <Typography sx={{ fontFamily: "Bungee Inline" }}>
                Logout
              </Typography>
            </Button>
          </Link>
          </div>
        )}

        <Button variant="contained" sx={{backgroundColor:"red"}} onClick={closeHandler}>Close</Button>
        </div>
      </div>
    </div>
  );
}
