import Home from "./pages/Home";
import Navbar from "./components/navbar/Navbar";

import ArticlePage from "./pages/ArticlePage";
import Search from "./pages/Search";
import { useState, useEffect } from "react";
import { Routes, Route, Switch } from "react-router-dom";
import mainStyles from "./App.module.css";



import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";

import Modal from "./components/Modal";

import {articles} from './articles/articles';

function App() {
  
  const [articleValue, setArticles] = useState(articles);


  const backgroundStyle={
    backgroundColor: "black",
  }

  return (
    
    <div className={mainStyles.maindiv} style={ backgroundStyle }>
       {/*  <Modal/> */}
        <Navbar/>
      <Routes>
          <Route path="/" element={<Home articles={articleValue} />} />
          <Route path="/article/:id" element={<ArticlePage articles={articleValue}/>}/>
          <Route path="/search" element={<Search articles={articleValue}/>}></Route>
          <Route path="/aboutus" element={<AboutPage/>}></Route>
        </Routes>
      <Footer/>
    </div>
   
  );
}

export default App;

