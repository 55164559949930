import styles from './Carousel.module.css'
import { useState,useEffect } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



export default function Carousel(props){
    const [currentIndex, setCurrentIndex]=useState(0);
    const [currentHeadline,setCurrentHeadline]=useState(props.data[currentIndex].headline)
   
    useEffect(()=>{
     const timer= setTimeout(()=>{
        goToNext();
      },"5000");
      return()=>clearTimeout(timer);
    },[currentIndex]);

    //Going to the previous image of the carousel
const goToPrevious=()=>{
    if(currentIndex==0){
      setCurrentIndex(props.data.length-1);
      setCurrentHeadline(props.data[props.data.length-1].headline);
    }else{
      setCurrentIndex(currentIndex-1);
      setCurrentHeadline(props.data[currentIndex-1].headline);
    }
   
  }
  
  //Going to the next image of the carousel
  const goToNext=()=>{
    if(currentIndex==props.data.length-1){
      setCurrentIndex(0);
      setCurrentHeadline(props.data[0].headline);
    }else{
      
      setCurrentIndex(currentIndex+1);
      setCurrentHeadline(props.data[currentIndex+1].headline);
    }
    clearTimeout();
  }
  
const mainDivStyle={
    width:"100%",
    height:"50vh",
    display:"flex",
    flexDirection:"row",
    marginBottom:30
   
}

const slideStyles={
    
    width:'100%',
    height:'100%',
    backgroundPosition:'center',
    backgroundSize:"contain",
    backgroundRepeat:"no-repeat",
    backgroundImage:`url(${props.data[currentIndex].image})`,
    
  }

  const carouselTextStyle={ 
    width:'70%',
    top:'80%',
    zIndex:2,
    color:'white',
    fontweight:'bold',
    backgroundColor:'#DC143C',
    borderRadius:'5px',
    fontFamily:"Bungee Inline",
    filter:"drop-shadow(10px 10px black)",
  }

  const likeFunc=()=>{
    window.location.replace(`/#/article/${props.data[currentIndex].id}`);
  }

    return(
        <div style={mainDivStyle}>
            <span className={styles.leftArrow} onClick={goToPrevious}><ArrowBackIosIcon sx={{color:"white"}}/></span>
            <span className={styles.centerItem}>
                
                <div style={slideStyles} className={styles.image} onClick={likeFunc}/>
                <span style={carouselTextStyle} className={styles.headline}>{currentHeadline}</span>
            </span>
            <span className={styles.rightArrow} onClick={goToNext}><ArrowForwardIosIcon sx={{color:"white"}}/></span>
        </div>
    )
}
