import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import styles from './Searchbutton.module.css';

export default function Searchbutton()
{
    const textStyle ={
        fontFamily:"Bungee",
        display:{
            xs:"none",
            sm:"none",
            md:"contents",
            lg:"contents",
            xl:"contents"
        }
    }

 return <Link to="/search" className={styles.searchButtonStyle}>
        <SearchIcon/>
        <Typography sx={textStyle}> Search</Typography>
        
    </Link> 
}