import {Card, CardContent, Typography } from "@mui/material";
import styles from './Article.module.css';
import {Link} from "react-router-dom";
import { useState,useEffect, useRef } from "react";



//Requires that you pass an object of the article entry as a prop
export default function Article(props) {
   
  const [data, setData] = useState(props.data);
  //const currentData=useRef(data);
  //const articleMount = useRef(false);

  const textStyle={
    fontFamily:"Kanit",
    fontSize:{
      xs:'1em',
      sm:'1em',
      md:'1em',
      lg:'1em',
      xl:'1em',
    }
  }

  const headLineStyle={
  color:"navy", 
  '&:hover':{textDecoration:"underline"},  
  fontSize:{
    xs:'1em',
    sm:'1em',
    md:'1em',
    lg:'1em',
    xl:'1em',
  },
  textAlign: 'left',
  fontFamily: 'Bungee',
  fontWeight:'bold',
}

 

  return (
   
   <Card sx={{minWidth:180, width:0.8, height:0.8, backgroundColor:"#F5F5F5",'&:hover':{backgroundColor:"white"}}}>
    <CardContent>
    
    <div className={styles.articlediv}>
     
    <Link style={{color:"inherit", textDecoration:"inherit"}} to={`/article/${data.id}`}>
      <img className={styles.image} src={data.image} ></img>
      </Link>
      <div>
      <Link style={{color:"inherit", textDecoration:"inherit"}} to={`/article/${data.id}`}>

       <Typography sx={headLineStyle}>
       {data.headline}
        </Typography>
        </Link>
      {/* Only display the first 55 characters of the post and add ... if over that */}
      {data.text.length>55 && <Typography sx={textStyle}>{data.text.substring(0,55)+"..."}</Typography>}
      
      {data.text.length>55 && <Link to={`/article/${data.id}`}><section className={styles.readMore}>Click to read more</section></Link>}
      {data.text.length<55 && <Typography sx={textStyle}>{data.text}</Typography>}
      </div>  
      </div>
      <br/>
       

    </CardContent>
    
   </Card>
   
  );
}