import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Card, Stack } from "@mui/material";
import { useState, useRef} from "react";
import Article from "../components/Article";

export default function Search(props) {
  const searchRef = useRef();
  const searchHandler = (event) => {
    
   //Update the reference to what is being searched
      searchRef.current = event.target.value;
      getArticles();
    };

const [message,SetMessage]=useState(false);
const [articles,SetArticles]=useState();


const getArticles=()=>{
let currentArticles=props.articles
  .filter((entry) => {
    //make the search term lowercase and check the array of articles
    const result = entry.text.toLowerCase().includes(searchRef.current.toLowerCase());
      return(result);
  })
  .map((x) => (
    <Article key={x.id} data={x} />
  ))
    SetArticles(currentArticles);
   //If the length  of the articles returned from search is 0, display the message
    if(currentArticles.length===0){SetMessage(true);}
   else{SetMessage(false);}
}

  return (
    <Box
      component="form"
      sx={{
        height: "100%",
        color: "white",
      }}
      noValidate
      autoComplete="off"
    >
      <Card
        sx={{
          backgroundColor: "white",
          width: 0.8,
          height: 0.9,
          margin: "auto",
          display:"flex",
          alignItems:"center",
          flexDirection:"column"
        }}
      >
        <TextField
          sx={{margin: 5 }}
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={searchHandler}
        />

        {/*  If the searchRef is not empty display the results   */}
        {searchRef.current && (
          <Stack sx={{alignItems:"center"}}> 
              {articles}
          </Stack>
        )}
         { message && <h1 style={{fontFamily:"Bungee"}}>No Results :(</h1>}
      </Card>
    </Box>
  );
}
