import { Container,Box} from "@mui/system";
import { Grid} from "@mui/material";
import {Typography} from "@mui/material";
import { Link } from "react-router-dom";
import styles from './Footer.module.css';

export default function Footer() {
  const boxStyle = { backgroundColor:"black",mt: 10};

  const boxItemStyle = {
    color:"white",
    fontFamily:"Bungee Inline",
    fontSize: { xs: "2rem", sm: "2rem", md: "2rem", lg: "2rem" },
    borderBottom: 5,
    alignItems:"right"
    
    
  };

  const gridItemStyle={
    textAlign:"center"
  }

  const signatureStyle={
    fontFamily: 'Fasthand',
    color:"white",
    textAlign:"center"
  }

  return (
    <Box sx={boxStyle}>
      <Container maxWidth="lg" sx={{mb:7}}>
        <Typography sx={signatureStyle}>Made By Benyamin Carmeli</Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} sx={gridItemStyle}>
            <Box sx={boxItemStyle}>Contact</Box>
            
                <a href="mailto:sportylemur@sportylemur.com" className={styles.linkStyle}>
             Email
            </a>
           
          </Grid>
          <Grid item xs={12} sm={4} sx={gridItemStyle}>
            <Box sx={boxItemStyle}>About</Box>

             <Link to="/aboutus" className={styles.linkStyle}>
                About Us
            </Link>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
